import { store } from '@/store';
import languageMessages from './languageMessages';

export default {
	name: 'DeterMixin',

	mixins: [languageMessages],

	computed: {
		isDeterProject() {
			return /deter/gi.test(store.state.myProject.titre);
		},
		sessionOptions() {
			return [
				{
					value: 'session_1_a',
					text: this.FormMSG(87000, 'Session-1 Team A')
				},
				{
					value: 'session_1_b',
					text: this.FormMSG(87001, 'Session-1 Team B')
				},
				{
					value: 'session_2_a',
					text: this.FormMSG(57002, 'Session-2 Team A')
				},
				{
					value: 'session_2_b',
					text: this.FormMSG(87003, 'Session-2 Team B')
				},
				{
					value: 'session_3_a',
					text: this.FormMSG(87004, 'Session-3 Team A')
				},
				{
					value: 'session_3_b',
					text: this.FormMSG(87005, 'Session-3 Team B')
				},
				{
					value: 'session_4_a',
					text: this.FormMSG(87006, 'Session-4 Team A')
				},
				{
					value: 'session_4_b',
					text: this.FormMSG(87007, 'Session-4 Team B')
				},
				{
					value: 'session_5_a',
					text: this.FormMSG(87008, 'Session-5 Team A')
				},
				{
					value: 'session_5_b',
					text: this.FormMSG(87901, 'Session-5 Team B')
				},
				{
					value: 'session_6_a',
					text: this.FormMSG(87902, 'Session-1 Team A')
				},
				{
					value: 'session_6_b',
					text: this.FormMSG(87010, 'Session-6 Team B')
				},
				{
					value: 'session_7_a',
					text: this.FormMSG(87011, 'Session-7 Team A')
				},
				{
					value: 'session_7_b',
					text: this.FormMSG(87012, 'Session-7 Team B')
				},
				{
					value: 'session_8_a',
					text: this.FormMSG(87013, 'Session-8 Team A')
				},
				{
					value: 'session_8_b',
					text: this.FormMSG(87014, 'Session-8 Team B')
				}
			];
		}
	}
};
