var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$screen.width <= 992
    ? _c("div", { staticClass: "animated fadeIn" }, [
        _vm.myProject
          ? _c("div", { staticClass: "container-mobile" }, [
              _c(
                "div",
                { staticClass: "my-dashboard-mobile" },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("b-col", { attrs: { cols: 6 } }, [
                        _c("div", { staticClass: "title" }, [
                          _c(
                            "h1",
                            {
                              staticClass: "main-page-title",
                              class: { "is-pwa": _vm.$isPwa() },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(1, "Welcome back")) +
                                  ", "
                              ),
                              _vm.$has(_vm.myProfile)
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.myProfile.firstName)),
                                  ])
                                : _vm._e(),
                              _vm._v(".\n\t\t\t\t\t\t"),
                            ]
                          ),
                        ]),
                      ]),
                      _vm.canSeeProd
                        ? _c(
                            "b-col",
                            {
                              staticClass: "row text-right",
                              staticStyle: {
                                "{\n\t\t\t\t\t\t\talign-items": "center",
                                "justify-content": "end",
                              },
                              attrs: {
                                cols: _vm.$screen.width <= 576 ? 12 : 6,
                              },
                            },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mr-3",
                                  attrs: { name: "some-radios", value: 0 },
                                  model: {
                                    value: _vm.selectedDashboard,
                                    callback: function ($$v) {
                                      _vm.selectedDashboard = $$v
                                    },
                                    expression: "selectedDashboard",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.FormMSG(25201, "Personal")))]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  attrs: { name: "some-radios", value: 1 },
                                  model: {
                                    value: _vm.selectedDashboard,
                                    callback: function ($$v) {
                                      _vm.selectedDashboard = $$v
                                    },
                                    expression: "selectedDashboard",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.FormMSG(26531, "Production"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.myProject &&
                  (_vm.myProject.useTimesheet ||
                    _vm.myProject.useExpense ||
                    _vm.myProject.usePurchaseOrder)
                    ? _c("div", { staticClass: "onboarding-actions" }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex justify-content-center wapper",
                          },
                          [
                            _vm.myProject && _vm.myProject.useTimesheet
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "btn-action gap-right",
                                    attrs: {
                                      to: "/mytimesheets",
                                      tag: "div",
                                      id: "action-add-workday",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "svgs/timesheet.svg",
                                        alt: "Timesheet",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(29, "Workday"))
                                      ),
                                    ]),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "action-add-workday",
                                          placement: "bottom",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(30, "Add Workday")
                                            ) +
                                            "\n\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.myProject && _vm.myProject.useExpense
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "btn-action gap-right",
                                    attrs: {
                                      to: "/myexpenses",
                                      tag: "div",
                                      id: "action-add-expense",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "svgs/expense.svg",
                                        alt: "Expense",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(31, "Expense"))
                                      ),
                                    ]),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "action-add-expense",
                                          placement: "bottom",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(32, "Add Expense")
                                            ) +
                                            "\n\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.myProject &&
                            _vm.myProject.usePurchaseOrder &&
                            !_vm.isFilmSingle
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "btn-action gap-right",
                                    attrs: {
                                      to: "/mypo",
                                      tag: "div",
                                      id: "action-add-po",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "svgs/po.svg",
                                        alt: "Purshase order",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(33, "Purchase Order")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "action-add-po",
                                          placement: "bottom",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                34,
                                                "Add Purchase Order"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isDeterProject === true
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "btn-action",
                                    attrs: {
                                      to: "/contacts",
                                      tag: "div",
                                      id: "action-contact",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "svgs/profile.svg",
                                        alt: "Contact",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(105, "Contact"))
                                      ),
                                    ]),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "action-contact",
                                          placement: "bottom",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(105, "Contact"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isFilmSingle
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "btn-action",
                                    attrs: { id: "action-share-profile" },
                                    on: {
                                      click: _vm.openShareSubscriptionModal,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "svgs/profile.svg",
                                        alt: "Share Profile",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(51, "Share Profile"))
                                      ),
                                    ]),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "action-share-profile",
                                          placement: "bottom",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(55, "Share Profile")
                                            ) +
                                            "\n\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("ShareInformation", {
                    ref: "modalShareSubscriptionSolo",
                    attrs: {
                      "btn-label": _vm.FormMSG(169, "Share"),
                      "title-modal": _vm.FormMSG(
                        170,
                        "Send your personal informations sheet"
                      ),
                      "msg-success": _vm.FormMSG(
                        250,
                        "Profile successfully sent"
                      ),
                      "send-a-copy": "",
                      "hide-button": "",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectedDashboard == 0,
                          expression: "selectedDashboard == 0",
                        },
                      ],
                      staticClass: "content",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "header d-flex pb-1 align-items-center",
                        },
                        [
                          _c("div", { staticClass: "project-title" }, [
                            _vm._v(
                              _vm._s(_vm.myProject ? _vm.myProject.titre : "")
                            ),
                          ]),
                          _c(
                            "router-link",
                            {
                              staticClass: "switch-label ml-auto",
                              attrs: { to: "/myproject", tag: "div" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end align-items-center pr-1",
                                },
                                [
                                  _c("refresh-cw", {
                                    attrs: {
                                      size: "16",
                                      id: "switch-to-another-project",
                                    },
                                  }),
                                  _c("span", { staticClass: "pl-1" }, [
                                    _vm._v(_vm._s(_vm.FormMSG(56, "Switch"))),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "grid-project-resume" },
                        [
                          _vm.myProject && _vm.myProject.useTimesheet
                            ? _c(
                                "router-link",
                                {
                                  class: `block-resume${
                                    _vm.isNoneBorderProjectResume(1)
                                      ? " none-border"
                                      : ""
                                  }`,
                                  attrs: { to: "/mytimesheets", tag: "div" },
                                },
                                [
                                  _vm.canSeeSalary
                                    ? _c("div", { staticClass: "amount" }, [
                                        _vm._v(
                                          _vm._s(_vm.formattedKPIS.salaries)
                                        ),
                                      ])
                                    : _c("div", { staticClass: "amount" }),
                                  _c("div", { staticClass: "label" }, [
                                    _vm._v(_vm._s(_vm.FormMSG(36, "Salaries"))),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c(_vm.getLucideIcon("ChevronRight"), {
                                        tag: "component",
                                        attrs: { color: "#06263E", size: 22 },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.myProject && _vm.myProject.useExpense
                            ? _c(
                                "router-link",
                                {
                                  class: `block-resume${
                                    _vm.isNoneBorderProjectResume(2)
                                      ? " none-border"
                                      : ""
                                  }`,
                                  attrs: { to: "/myexpenses", tag: "div" },
                                },
                                [
                                  _c("div", { staticClass: "amount" }, [
                                    _vm._v(_vm._s(_vm.formattedKPIS.expenses)),
                                  ]),
                                  _c("div", { staticClass: "label" }, [
                                    _vm._v(_vm._s(_vm.FormMSG(37, "Expenses"))),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c(_vm.getLucideIcon("ChevronRight"), {
                                        tag: "component",
                                        attrs: { color: "#06263E", size: 22 },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.myProject &&
                          _vm.myProject.usePurchaseOrder &&
                          !_vm.isFilmSingle
                            ? _c(
                                "router-link",
                                {
                                  class: `block-resume${
                                    _vm.isNoneBorderProjectResume(3)
                                      ? " none-border"
                                      : ""
                                  }`,
                                  attrs: { to: "/mypo", tag: "div" },
                                },
                                [
                                  _c("div", { staticClass: "amount" }, [
                                    _vm._v(_vm._s(_vm.formattedKPIS.pos)),
                                  ]),
                                  _c("div", { staticClass: "label" }, [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(38, "Purchase Orders"))
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c(_vm.getLucideIcon("ChevronRight"), {
                                        tag: "component",
                                        attrs: { color: "#06263E", size: 22 },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      !_vm.isFilmSingle
                        ? _c("div", { staticClass: "notifications" }, [
                            _c(
                              "div",
                              { staticClass: "header d-flex pt-3 pb-2" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "project-title w-100" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(39, "My Notifications")
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "grid-project-resume" }, [
                              _vm.totalNotification > 0
                                ? _c(
                                    "div",
                                    [
                                      _vm.totalDocToRead > 0
                                        ? _c(
                                            "router-link",
                                            {
                                              class: `block-resume${
                                                _vm.isNoneBorderNotification(1)
                                                  ? " none-border"
                                                  : ""
                                              }`,
                                              attrs: {
                                                to: "/profile?forDocs=true",
                                                tag: "div",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "label d-flex w-95",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "notification",
                                                    },
                                                    [
                                                      _c("b-badge", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.totalDocToRead
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "type" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            52,
                                                            "New Documents"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "ChevronRight"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#06263E",
                                                        size: 22,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.totalAccToRead > 0
                                        ? _c(
                                            "router-link",
                                            {
                                              class: `block-resume${
                                                _vm.isNoneBorderNotification(2)
                                                  ? " none-border"
                                                  : ""
                                              }`,
                                              attrs: {
                                                to: "/myaccomodations",
                                                tag: "div",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "label d-flex w-95",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "notification",
                                                    },
                                                    [
                                                      _c("b-badge", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.totalAccToRead
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "type" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            53,
                                                            "New Accomodations"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "ChevronRight"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#06263E",
                                                        size: 22,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.myProject &&
                                      _vm.myProject.useTimesheet &&
                                      _vm.totalTsToValidate > 0
                                        ? _c(
                                            "div",
                                            {
                                              class: `block-resume${
                                                _vm.isNoneBorderNotification(3)
                                                  ? " none-border"
                                                  : ""
                                              }`,
                                              attrs: { tag: "div" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleClick(
                                                    "mytimesheets"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "label d-flex w-95",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "notification",
                                                    },
                                                    [
                                                      _c("b-badge", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.totalTsToValidate
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "type" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            40,
                                                            "Timesheets to validate"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "ChevronRight"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#06263E",
                                                        size: 22,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.myProject &&
                                      _vm.myProject.useExpense &&
                                      _vm.totalExpToValidate > 0
                                        ? _c(
                                            "div",
                                            {
                                              class: `block-resume${
                                                _vm.isNoneBorderNotification(4)
                                                  ? " none-border"
                                                  : ""
                                              }`,
                                              attrs: { tag: "div" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleClick(
                                                    "myexpense"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "label d-flex w-95",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "notification",
                                                    },
                                                    [
                                                      _c("b-badge", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.totalExpToValidate
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "type" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            42,
                                                            "Expenses to validate"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "ChevronRight"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#06263E",
                                                        size: 22,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.myProject &&
                                      _vm.myProject.usePurchaseOrder &&
                                      _vm.totalPoToValidate > 0
                                        ? _c(
                                            "div",
                                            {
                                              class: `block-resume${
                                                _vm.isNoneBorderNotification(5)
                                                  ? " none-border"
                                                  : ""
                                              }`,
                                              attrs: { tag: "div" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleClick("mypo")
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "label d-flex w-95",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "notification",
                                                    },
                                                    [
                                                      _c("b-badge", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.totalPoToValidate
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "type" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            43,
                                                            "Purchase orders to validate"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "ChevronRight"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#06263E",
                                                        size: 22,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.totalTsRefused > 0
                                        ? _c(
                                            "router-link",
                                            {
                                              class: `block-resume${
                                                _vm.isNoneBorderNotification(6)
                                                  ? " none-border"
                                                  : ""
                                              }`,
                                              attrs: {
                                                to: "/mytimesheets",
                                                tag: "div",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "label d-flex w-95",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "notification",
                                                    },
                                                    [
                                                      _c("b-badge", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.totalTsRefused
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "type" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            60,
                                                            "Timesheets refused"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "ChevronRight"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#06263E",
                                                        size: 22,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.totalExpRefused > 0
                                        ? _c(
                                            "router-link",
                                            {
                                              class: `block-resume${
                                                _vm.isNoneBorderNotification(7)
                                                  ? " none-border"
                                                  : ""
                                              }`,
                                              attrs: {
                                                to: "/mytimesheets",
                                                tag: "div",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "label d-flex w-95",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "notification",
                                                    },
                                                    [
                                                      _c("b-badge", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.totalExpRefused
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "type" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            61,
                                                            "Expenses refused"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "ChevronRight"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#06263E",
                                                        size: 22,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.totalPoRefused > 0
                                        ? _c(
                                            "router-link",
                                            {
                                              class: `block-resume${
                                                _vm.isNoneBorderNotification(8)
                                                  ? " none-border"
                                                  : ""
                                              }`,
                                              attrs: {
                                                to: "/mypo",
                                                tag: "div",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "label d-flex w-95",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "notification",
                                                    },
                                                    [
                                                      _c("b-badge", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.totalPoRefused
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "type" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            62,
                                                            "Purchase orders refused"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "ChevronRight"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#06263E",
                                                        size: 22,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.totalExpItemRefused > 0
                                        ? _c(
                                            "router-link",
                                            {
                                              class: `block-resume${
                                                _vm.isNoneBorderNotification(9)
                                                  ? " none-border"
                                                  : ""
                                              }`,
                                              attrs: {
                                                to: "/mytimesheets",
                                                tag: "div",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "label d-flex w-95",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "notification",
                                                    },
                                                    [
                                                      _c("b-badge", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.totalExpItemRefused
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "type" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            64,
                                                            "Expense items refused"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "ChevronRight"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#06263E",
                                                        size: 22,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "block-resume none-border",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "label w-100" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    54,
                                                    "Everything has been put away, sorted and classified."
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.myProject &&
                      _vm.myProject.useDocument &&
                      !_vm.isFilmSingle
                        ? _c("div", { staticClass: "documents" }, [
                            _c(
                              "div",
                              { staticClass: "header d-flex pt-3 pb-2" },
                              [
                                _c("div", { staticClass: "project-title" }, [
                                  _vm._v(
                                    _vm._s(_vm.FormMSG(44, "My Documents"))
                                  ),
                                ]),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "switch-label ml-auto",
                                    attrs: {
                                      to: "/profile?forDocs=true",
                                      tag: "div",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(45, "View all documents")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "documents-resume" }, [
                              _c(
                                "div",
                                { staticClass: "document-list w-100" },
                                [
                                  _vm.documents.length > 0
                                    ? _c(
                                        "ul",
                                        _vm._l(
                                          _vm.documents.slice(0, 3),
                                          function (Doc) {
                                            return _c("li", { key: Doc.id }, [
                                              _c(
                                                "span",
                                                { staticClass: "icon" },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "FileText"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: { size: 20 },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "filename" },
                                                [_vm._v(_vm._s(Doc.name))]
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "carbon-footprint-container" },
                        [_c("carbon-dashboard", { attrs: { level: "user" } })],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectedDashboard == 1,
                          expression: "selectedDashboard == 1",
                        },
                      ],
                      staticClass: "content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "carbon-footprint-container" },
                        [
                          _c("carbon-dashboard"),
                          _c("project-kpis"),
                          _c("budget-dashboard"),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ])
    : _c(
        "div",
        {
          staticClass: "animated fadeIn",
          staticStyle: { "font-size": "1rem" },
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("b-col", { attrs: { cols: 6 } }, [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "h1",
                    {
                      staticClass: "main-page-title",
                      class: { "is-pwa": _vm.$isPwa() },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(1, "Welcome back")) +
                          ", "
                      ),
                      _vm.$has(_vm.myProfile)
                        ? _c("span", [_vm._v(_vm._s(_vm.myProfile.firstName))])
                        : _vm._e(),
                      _vm._v(".\n\t\t\t\t"),
                    ]
                  ),
                ]),
              ]),
              _vm.canSeeProd
                ? _c(
                    "b-col",
                    {
                      staticClass: "row text-right",
                      staticStyle: {
                        "{\n\t\t\t\t\talign-items": "center",
                        "justify-content": "end",
                      },
                      attrs: { cols: 6 },
                    },
                    [
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "mr-3",
                          attrs: { name: "some-radios", value: 0 },
                          model: {
                            value: _vm.selectedDashboard,
                            callback: function ($$v) {
                              _vm.selectedDashboard = $$v
                            },
                            expression: "selectedDashboard",
                          },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(25201, "Personal")))]
                      ),
                      _c(
                        "b-form-radio",
                        {
                          attrs: { name: "some-radios", value: 1 },
                          model: {
                            value: _vm.selectedDashboard,
                            callback: function ($$v) {
                              _vm.selectedDashboard = $$v
                            },
                            expression: "selectedDashboard",
                          },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(26531, "Production")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.myProject &&
          (_vm.myProject.useTimesheet ||
            _vm.myProject.useExpense ||
            _vm.myProject.usePurchaseOrder)
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "card-border-blue-light",
                          attrs: { id: "onboarding-top-btns" },
                        },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c(
                                "b-row",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _vm.myProject && _vm.myProject.useTimesheet
                                    ? _c(
                                        "b-col",
                                        { attrs: { sm: "4" } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "d-flex justify-content-center align-items-center",
                                              staticStyle: {
                                                "font-size": "1.25rem",
                                              },
                                              attrs: {
                                                block: "",
                                                variant: "outline-primary",
                                                size: "md",
                                                href: "#",
                                                to: "/mytimesheets",
                                              },
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  "CalendarDays"
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: { size: 22 },
                                                }
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ml-3" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        26,
                                                        "Add Timesheet"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.myProject && _vm.myProject.useExpense
                                    ? _c(
                                        "b-col",
                                        { attrs: { sm: "4" } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "d-flex justify-content-center align-items-center",
                                              staticStyle: {
                                                "font-size": "1.25rem",
                                              },
                                              attrs: {
                                                block: "",
                                                variant: "outline-primary",
                                                size: "md",
                                                href: "#",
                                                to: "/myexpenses",
                                              },
                                            },
                                            [
                                              _c(_vm.getLucideIcon("Wallet"), {
                                                tag: "component",
                                                attrs: { size: 22 },
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "ml-3" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        27,
                                                        "Add Expense"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.myProject &&
                                  _vm.myProject.usePurchaseOrder &&
                                  !_vm.isFilmSingle
                                    ? _c(
                                        "b-col",
                                        { attrs: { sm: "4" } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "last d-flex justify-content-center align-items-center",
                                              staticStyle: {
                                                "font-size": "1.25rem",
                                              },
                                              attrs: {
                                                block: "",
                                                variant: "outline-primary",
                                                size: "md",
                                                href: "#",
                                                to: "/mypo",
                                              },
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  "ShoppingCart"
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: { size: 22 },
                                                }
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ml-3" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        28,
                                                        "Add Purchase Order"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectedDashboard == 0,
                  expression: "selectedDashboard == 0",
                },
              ],
            },
            [
              _c(
                "b-col",
                { attrs: { md: "8" } },
                [
                  _c(
                    "div",
                    { staticClass: "clearfix d-flex align-items-center" },
                    [
                      _c(
                        "h2",
                        {
                          staticClass: "block-sm w-50",
                          attrs: { id: "active-project" },
                        },
                        [_vm._v(_vm._s(this.getActiveProjectLabel()))]
                      ),
                      _c(
                        "b-link",
                        {
                          staticClass:
                            "text-uppercase mt-2 block-sm w-50 text-right mr-1",
                          attrs: { to: "/myproject" },
                        },
                        [_vm._v(_vm._s(this.getSwitchProjectLabel()))]
                      ),
                    ],
                    1
                  ),
                  _vm.myProject !== null
                    ? _c(
                        "b-card",
                        { staticClass: "card-border-blue-light" },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col", { attrs: { md: "4" } }, [
                                    _c("h4", { staticClass: "text-left" }, [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.myProject
                                              ? _vm.myProject.titre
                                              : ""
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("img", {
                                      staticClass: "project-picture-preview",
                                      attrs: {
                                        src: this.imageName,
                                        loading: "lazy",
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "font-color",
                                      attrs: { md: "8" },
                                    },
                                    [
                                      _vm.myProject &&
                                      _vm.myProject.useTimesheet
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "clearfix border-bottom p-2",
                                            },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(4, "Salaries")
                                                  )
                                                ),
                                              ]),
                                              _c("br"),
                                              _vm.canSeeSalary
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-primary",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formattedKPIS
                                                            .salaries
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "b-link",
                                                {
                                                  staticClass:
                                                    "text-uppercase float-right",
                                                  attrs: {
                                                    to: "/mytimesheets",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        5,
                                                        "View salaries"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.myProject && _vm.myProject.useExpense
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "clearfix border-bottom p-2",
                                            },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(6, "Expenses")
                                                  )
                                                ),
                                              ]),
                                              _c("br"),
                                              _c(
                                                "span",
                                                { staticClass: "text-primary" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formattedKPIS.expenses
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-link",
                                                {
                                                  staticClass:
                                                    "text-uppercase float-right",
                                                  attrs: { to: "/myexpenses" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        7,
                                                        "View expenses"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.myProject &&
                                      _vm.myProject.usePurchaseOrder &&
                                      !_vm.isFilmSingle
                                        ? _c(
                                            "div",
                                            { staticClass: "clearfix p-2" },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.FormMSG(8, "P.O."))
                                                ),
                                              ]),
                                              _c("br"),
                                              _c(
                                                "span",
                                                { staticClass: "text-primary" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formattedKPIS.pos
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-link",
                                                {
                                                  staticClass:
                                                    "text-uppercase float-right",
                                                  attrs: { to: "/mypo" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        9,
                                                        "View P.O."
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  !_vm.isFilmSingle
                    ? _c("h2", [_vm._v(_vm._s(_vm.FormMSG(10, "What's next")))])
                    : _vm._e(),
                  _c(
                    "b-card",
                    { staticClass: "card-border-blue-light" },
                    [
                      _c(
                        "b-card-body",
                        [
                          _vm.isFilmSingle
                            ? _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("div", { staticClass: "custom-card" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-card-body text-center mb-5",
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(41, "MY PROFILE")
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-card-footer text-center",
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                to: "/profile",
                                                tag: "b-button",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(168, "View")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                          _c("ShareInformation", {
                                            attrs: {
                                              "btn-label": _vm.FormMSG(
                                                169,
                                                "Share"
                                              ),
                                              "title-modal": _vm.FormMSG(
                                                170,
                                                "Send your personal informations sheet"
                                              ),
                                              "msg-success": _vm.FormMSG(
                                                250,
                                                "Profile successfully sent"
                                              ),
                                              "send-a-copy": "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isFilmSingle &&
          _vm.myProject &&
          _vm.myProject.useDocument &&
          _vm.selectedDashboard == 0
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "onboarding-notifications",
                      attrs: { md: "6" },
                    },
                    [
                      _c("h2", [
                        _vm._v(_vm._s(_vm.FormMSG(11, "My notifications"))),
                      ]),
                      _c(
                        "b-card",
                        { staticClass: "card-border-blue-light" },
                        [
                          _c("b-card-body", { staticClass: "font-color" }, [
                            _vm.totalNotification > 0
                              ? _c("div", [
                                  _vm.totalDocToRead > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "clearfix border-bottom p-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-6" },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "badge-variant",
                                                  attrs: { pill: "" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge-notification",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.totalDocToRead
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b", { staticClass: "w-94" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(12, "new Documents")
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "b-link",
                                            {
                                              staticClass:
                                                "text-uppercase float-right",
                                              attrs: {
                                                to: "/profile?forDocs=true",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(13, "View"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.totalAccToRead > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "clearfix border-bottom p-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-6" },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "badge-variant",
                                                  attrs: { pill: "" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge-notification",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.totalAccToRead
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b", { staticClass: "w-94" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  14,
                                                  "new accomodations"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "b-link",
                                            {
                                              staticClass:
                                                "text-uppercase float-right",
                                              attrs: { to: "/myaccomodations" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(13, "View"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.myProject &&
                                  _vm.myProject.useTimesheet &&
                                  _vm.totalTsToValidate > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "clearfix d-flex border-bottom p-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-6" },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "badge-variant",
                                                  attrs: { pill: "" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge-notification",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.totalTsToValidate
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b", { staticClass: "w-94" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  15,
                                                  "timesheets to validate"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "b-link",
                                            {
                                              staticClass:
                                                "text-uppercase float-right",
                                              attrs: {
                                                to: "/mytimesheets?validate=true",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(13, "View"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.myProject &&
                                  _vm.myProject.useExpense &&
                                  _vm.totalExpToValidate > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "clearfix d-flex border-bottom p-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-6" },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "badge-variant",
                                                  attrs: { pill: "" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge-notification",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.totalExpToValidate
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b", { staticClass: "w-94" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  16,
                                                  "expenses to validate"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "b-link",
                                            {
                                              staticClass:
                                                "text-uppercase float-right",
                                              attrs: {
                                                to: "/myexpenses?validate=true",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(13, "View"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.myProject &&
                                  _vm.myProject.usePurchaseOrder &&
                                  _vm.totalPoToValidate > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "clearfix d-flex border-bottom p-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-6" },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "badge-variant",
                                                  attrs: { pill: "" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge-notification",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.totalPoToValidate
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b", { staticClass: "w-94" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  17,
                                                  "purchase orders to validate"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "b-link",
                                            {
                                              staticClass:
                                                "text-uppercase float-right",
                                              attrs: {
                                                to: "/mypo?validate=true",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(13, "View"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.totalTsRefused > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "clearfix d-flex border-bottom p-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-6" },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "badge-variant",
                                                  attrs: { pill: "" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge-notification",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.totalTsRefused
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b", { staticClass: "w-94" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  57,
                                                  "timesheets refused"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "b-link",
                                            {
                                              staticClass:
                                                "text-uppercase float-right",
                                              attrs: { to: "/mytimesheets" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(13, "View"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.totalExpRefused > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "clearfix d-flex border-bottom p-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-6" },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "badge-variant",
                                                  attrs: { pill: "" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge-notification",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.totalExpRefused
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b", { staticClass: "w-94" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  58,
                                                  "expenses refused"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "b-link",
                                            {
                                              staticClass:
                                                "text-uppercase float-right",
                                              attrs: { to: "/myexpenses" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(13, "View"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.totalPoRefused > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "clearfix d-flex border-bottom p-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-6" },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "badge-variant",
                                                  attrs: { pill: "" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge-notification",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.totalPoRefused
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b", { staticClass: "w-94" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  59,
                                                  "purchase orders refused"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "b-link",
                                            {
                                              staticClass:
                                                "text-uppercase float-right",
                                              attrs: { to: "/mypo" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(13, "View"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.totalExpItemRefused > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "clearfix d-flex border-bottom p-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-6" },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "badge-variant",
                                                  attrs: { pill: "" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge-notification",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.totalExpItemRefused
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b", { staticClass: "w-94" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  63,
                                                  "expense items refused"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "b-link",
                                            {
                                              staticClass:
                                                "text-uppercase float-right",
                                              attrs: { to: "/myexpenses" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(13, "View"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.myProject && _vm.totalTsToDelete > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "clearfix d-flex border-bottom p-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-6" },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "badge-variant",
                                                  attrs: { pill: "" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge-notification",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.totalTsToDelete
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b", { staticClass: "w-94" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  400,
                                                  "Request for deleting"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "b-link",
                                            {
                                              staticClass:
                                                "text-uppercase float-right",
                                              attrs: {
                                                to: "/time-cards/planning",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(13, "View"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ])
                              : _c("div", { staticClass: "empty-state" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(
                                          18,
                                          "Everything has been put away, sorted and classified!"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.myProject &&
                  _vm.myProject.useDocument &&
                  !_vm.isFilmSingle
                    ? _c(
                        "b-col",
                        {
                          staticClass: "onboarding-documents",
                          attrs: { md: "6" },
                        },
                        [
                          _c("div", { staticClass: "clearfix" }, [
                            _c("h2", { staticClass: "pull-left block-sm" }, [
                              _vm._v(_vm._s(_vm.FormMSG(20, "My documents"))),
                            ]),
                          ]),
                          _c(
                            "b-card",
                            { staticClass: "card-border-blue-light" },
                            [
                              _c("b-card-body", [
                                _vm.documents.length > 0
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.documents.slice(0, 3),
                                        function (Doc) {
                                          return _c(
                                            "div",
                                            {
                                              key: Doc.id,
                                              staticClass:
                                                "border-bottom clearfix d-flex align-items-center",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "w-75" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgba(6, 38, 62, 0.74)",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(Doc.name))]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-primary mt-0",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          Doc.type !== 11
                                                            ? Doc.fileName
                                                            : _vm.FormMSG(
                                                                104,
                                                                "Document Package"
                                                              )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-link",
                                                {
                                                  staticClass:
                                                    "text-uppercase w-25 text-right",
                                                  attrs: {
                                                    to: "/profile?forDocs=true",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(13, "View")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _c("div", { staticClass: "empty-state" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.FormMSG(
                                              180,
                                              "You have no document yet!"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectedDashboard == 0,
                  expression: "selectedDashboard == 0",
                },
              ],
            },
            [
              _c(
                "b-col",
                { staticClass: "dashboard-embed", attrs: { md: "12" } },
                [
                  _c("carbon-dashboard", {
                    staticClass: "font-color",
                    attrs: { level: "user" },
                  }),
                  _c("evolution-dashboard", { attrs: { level: "user" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectedDashboard == 1,
                  expression: "selectedDashboard == 1",
                },
              ],
            },
            [
              _c(
                "b-col",
                { staticClass: "dashboard-embed", attrs: { md: "12" } },
                [
                  _c("carbon-dashboard"),
                  _c("project-kpis"),
                  _c("budget-dashboard"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }